<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import addButton from "../../../components/widgets/addButton.vue";
import tagComponent from "./components/tagComponent.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProgress,
    addButton,
    tagComponent,
  },

  data() {
    return {
      spinner: false,
      process: false,
      progress: 0,
      titre: "",
      searchQuery: "",
      modalOk: false,
      tagsInfos: {
        titre: "",
      },
      tags: [],
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      title: "Liste des Tags",
      items: [
        {
          text: "Outils",
          // href: "/projects/tags",
        },
        {
          text: "Tags",
          active: true,
        },
      ],
    };
  },
  computed: {
    filteredUsersList() {
      if (!this.searchQuery) {
        return this.tags;
      }
      return this.tags.filter((tag) => {
        const fullName = tag.name;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },

    createTags() {
      this.process = true;
      this.modalOk = false;

      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout du tag";

      Api.post(
        "/streamvod/rest/v2/tag/create",
        {
          name: this.tagsInfos.titre,
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Success!", "Tag ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.spinner = true
    Api.get("/streamvod/api/v2/tag/all")
      .then((res) => {
        this.spinner = false
        this.tags = res.data.content;
      })
      .catch((error) => {
        this.process = false;
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div style="display: flex; justify-content: space-between">
        <div class="col-lg-6">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Rechercher un tag"
              style="border-radius: 8px"
              v-model="searchQuery"
            />
          </div>
        </div>
        <addButton :text="'Ajouter'" @click="showModal"></addButton>
      </div>
    </div>
    <br />

    <div class="row">
      <div
        class="col-lg-3"
        v-for="tag in filteredUsersList.slice().reverse()"
        :key="tag.id"
      >
        <tagComponent :tag="tag"></tagComponent>
      </div>

      <div style="text-align: center; font-size: 3em" v-if="spinner == true">
        <b-spinner variant="success" role="status"></b-spinner>
      </div>
      <div
        style="text-align: center; font-size: 3em"
        v-if="
          spinner == false &&
          filteredUsersList.length == 0
        "
      >
        <span style="font-size: 1.5rem">Aucun tag créé !</span>
      </div>
    </div>

    <b-modal
      v-model="modalOk"
      id="modal-ml"
      size="ml"
      title="Ajouter un tag"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label">Titre du tag</label>
            <div class="col-lg-12">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="tagsInfos.titre"
                placeholder="Entrez le nom du tag"
              />
            </div>
          </div>
        </form>

        <div style="display: flex; justify-content: center">
          <addButton :text="'Ajouter'" @click="createTags"></addButton>
        </div>
      </div>
    </b-modal>
    <loaderProgress :visible="process" :progress="progress" :title="titre" />
    <!-- end row -->
  </Layout>
</template>

<style></style>
